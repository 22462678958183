import axios from 'axios';
import React from 'react';
import { Button, Container, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default class Navigation extends React.Component {
    state = {
        receivers: 0,
        uuids: [],
        uuidsUrl: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            receivers: 0,
            uuids: [],
            uuidsUrl: ""
        };
    }

    async componentDidMount() {
        await axios.get('https://api.airplanes.live/stats')
        .then((d) => this.setState({receivers: d.data.beast}));

        await axios.get('https://api.airplanes.live/feed-status')
        .then((d) => {
            var uuids: string[];
            
            for (var v of d.data.feederReceivers) {
                var u = v[0].replace(/-/g,'');
                
                this.setState({uuids: this.state.uuids.push(u)});
            }            
        });

        this.setState({ uuidsUrl: `https://globe.airplanes.live/?feed=${this.state.uuids.join(",")}`});
    }

    render() {
        return (
            <Navbar expand='md' bg='dark' variant='dark' className='justify-content-center'>
                <Container fluid>
                    <Navbar.Brand href='/' className='w-50 me-auto'>ADS-B One</Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav' as='ul' className='w-100'>
                        <Nav className='w-100 justify-content-center'>
                            <Nav.Link href='/'>Home</Nav.Link>
                            <NavDropdown title='Map' id='nav-dropdown-map' menuVariant='dark'>
                                <Nav.Link href='https://globe.airplanes.live'>Map</Nav.Link>
                                <Nav.Link href={ this.state.uuidsUrl }>My Map</Nav.Link>
                                <Nav.Link href='/map-help'>Map Help</Nav.Link>
                            </NavDropdown>
                            <Nav.Link href='/getting-started'>Getting Started</Nav.Link>
                            <NavDropdown title='Coverage' id='nav-dropdown-coverage' menuVariant='dark'>
                                <Nav.Link href='/feeder-status'>Feeder Status</Nav.Link>
                                <Nav.Link href='/mlat-map'>MLAT Map</Nav.Link>
                            </NavDropdown>
                            <NavDropdown title='Data' id='nav-dropdown-data' menuVariant='dark'>
                                <Nav.Link href='https://github.com/ADSB-One/api/blob/main/README.md'>API</Nav.Link>
                                <Nav.Link href='https://adsb-one.statuspage.io/'>Status</Nav.Link>
                                <Nav.Link href='/data-tou'>Terms of Use</Nav.Link>
                            </NavDropdown>
                            <NavDropdown title='About' id='nav-dropdown-about' menuVariant='dark' style={{width: 'max-content !important'}}>
                                <Nav.Link href='/about'>About</Nav.Link>
                                <Nav.Link href='/faq'>FAQ</Nav.Link>
                                <Nav.Link href='/oss'>Open Source Software</Nav.Link>
                                <Nav.Link href='/partners'>Partners</Nav.Link>
                                <Nav.Link href='/privacy-policy'>Privacy Policy</Nav.Link>
                            </NavDropdown>
                        </Nav>
                        <Container className='d-flex w-100 justify-content-end'>
                            <p className='my-auto text-white'>Feeder count: {this.state.receivers}</p>
                        </Container>
                        <Button style={{backgroundColor: 'gray', borderColor: 'gray'}} href='https://discord.gg/adsb'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                            </svg>
                        </Button>
                        {/* <Form className='d-flex w-100 justify-content-end'>
                            <Form.Control
                                type='search'
                                placeholder='Mode S hex or registration'
                                className='mx-1'
                                aria-label='Search'
                            />
                            <Button variant='outline-success' className='mx-1'>Search</Button>
                            <Button variant='outline-secondary disabled'>Login</Button>
                        </Form> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}
