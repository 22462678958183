import React from 'react';
import { Accordion, Container } from 'react-bootstrap';

export default class MapHelp extends React.Component {


    render() {
        return (
            <Container fluid className='w-75 h-100'>
                <h1 className='text-center display-3'>Map Help</h1>

                <h4 className='display-5'>Search Box</h4>
                <p className='lead'>
                    tar1090 has a universal search box that will find ICAO number, registration number, and aircraft type.
                </p>

                <h4 className='display-5'>Filters</h4>
                <p className='lead'>
                    Click on Filters in the right sidebar to switch to the filtering tab. This allows you to filter by details such as altitude range, callsign, squawk, type, or type description.
                    <ul>
                        <li>. is a placeholder for an arbitrary character</li>
                        <li>Multiple patterns can be combined using |</li>
                        <li>Don't add any spaces, filtering won't work.</li>
                    </ul>
                    Examples for filtering type description:
                    <ul>
                        <li>Helicopters: H..</li>
                        <li>Landplanes with 2 jet engines: L2J</li>
                        <li>Landplanes with any number of piston engines: L.P</li>
                        <li>Helicopters any number of turbine engines : H.T</li>
                        <li>All turboprop powered things, including turbine helicopters: ..T</li>
                        <li>Everything with 4 engines: .4.</li>
                        <li>Everything with 2 3 and 4 engines: .2.|.3.|.4.</li>
                    </ul>
                    Examples for filtering by ICAO type code:
                    <ul>
                        <li>B737 family: B73.</li>
                        <li>A320 family: A32.</li>
                        <li>B737-900 and B737 Max 9: B739|B39M</li>
                        <li>737 family including max: B73.|B3.M</li>
                        <li>B737 / A320 families: B73.|B3.M|A32.|A2.N</li>
                        <li>Exclude a certain type: ^(?!A320)</li>
                        <li>Exclude multiple patterns: ^(?!(A32.|B73.))</li>
                    </ul>
                    Lookup page for ICAO type codes: <a href="https://www.icao.int/publications/DOC8643/Pages/Search.aspx">https://www.icao.int/publications/DOC8643/Pages/Search.aspx</a>
                </p>

                <h4 className='display-5'>Buttons</h4>
                <p className='lead'>
                    There are a number of buttons each containing a letter to turn on and off specific features, all of these buttons can be clicked with a mouse or just press the letter on your keyboard.
                    <ul>
                        <li><b>U</b> - Military/Interesting</li>
                        <li><b>T</b> - Tracks on for all aircraft</li>
                        <li><b>O</b> - Label extensions</li>
                        <li><b>B</b> - Map brightness</li>
                        <li><b>M</b> - Muliselect</li>
                        <li><b>I</b> - Isolate selected aircraft</li>
                        <li><b>F</b> - Follow plane (map moves)</li>
                        <li><b>H</b> - Home/Reset map</li>
                        <li><b>L</b> - Labels</li>
                        <li><b>K</b> - Track labels</li>
                        <li><b>P</b> - Persistence mode (leave planes on screen after signal lost)</li>
                        <li><b>R</b> - Follow random plane</li>
                    </ul>
                    +/- Lower Right = zoom in and out, you may also use your scroll wheel
                </p>

                <h4 className='display-5'>Map Layers Icon</h4>
                <p className='lead'>
                    Choose from a wide range of Maps, Charts, and overlays such as weather to display aircraft on. This is also where you turn on your location dot and range rings.
                </p>

                <h4 className='display-5'>Gear Icon</h4>
                <p className='lead'>
                    A number of Display options can be set here including Units of measure displayed.
                </p>

                <h4 className='display-5'>Aircraft List</h4>
                <p className='lead'>
                    You may click select an aircraft on the list just as you would click on it on the map

                    Note: The list is limited to about 100 aircraft so you may not see a plane you are looking at if you are zoomed out, click on it zoom in until there are less planes on the map and it will be listed.
                </p>

                <h4 className='display-5'>Airplane Colors</h4>
                <p className='lead'>
                    Planes are colored in a gradient of orange to purple then red that give a visual representation of the aircraft’s altitude. Gray aircraft are on the ground and their ADS-B transponder is transmitting in ground mode.
                </p>

                <h4 className='display-5'>Estimated Last Leg</h4>
                <p className='lead'>
                    Shift + L toggle estimated last leg vs full trace.
                </p>

                <h4 className='display-5'>Hide Buttons</h4>
                <p className='lead'>
                    If you would like to temporarily hide all the navigation buttons for a cleaner interface you may do so temporarily by hitting Shift + S key.
                </p>

                <h4 className='display-5'>Location & Range Rings</h4>
                <p className='lead'>
                    The map will attempt to detect your location automatically as long as you allow your browser to do provide the info, if you want a more precise locations, or a different location then where you currently are you can set it with a URL parameter like so:

                    https://globe.adsb.one/?SiteLat=XX.XXX&amp;SiteLon=-XX.XXX

                    This position is only present for this visit, so bookmark that URL and use it.

                    Range Rings can be turned on and off in the Map Layers Icon, there is no way to adjust the ranges displayed.
                </p>

                <h4 className='display-5'>Mobile Version Notes</h4>
                <p className='lead'>
                    The mobile version of the tar1090 has some layout changes to make it usable on smaller screens, also you will find it helpful to use the increase Icon size button to make it easier to work with.

                    The Mobile version also has a cap of displaying only 100 planes, this may present situations where you are zoomed out and only see lower flying aircraft because it loads from low to high. Zoom into an area for all aircraft to be seen.
                </p>

                <h4 className='display-5'>How to use URL Parameters</h4>
                <p className='lead'>
                    There are a number of setting for tar1090 then can be changed by entering parameters into the URL:
                    <ul>
                        <li>Before the first query parameter a question mark ? is prepended, for each additional parameter an ampersand & is prepended.</li>
                        <li>Example: http://globe.adsb.one/?airport=KJFK&amp;enableLabels&amp;extendedLabels=2</li>
                        <li>Some parameters need a value. If no value if passed, the default one is used.</li>
                        <li>Some parameters do not take a value. They are active if used in the URL. (hideSideBar, hideButtons, …).</li>
                        <li>Query parameters in tar1090 are not case sensitive.</li>
                    </ul>
                </p>
                
                <h4 className='display-5'>Common URL Parameters</h4>
                <p className='lead'>
                    <ul>
                        <li>icao=A1B2C3 - Centers, Selects, and Isolates the selected plane</li>
                        <li>icaoFilter=hex1,hex2,hex3 - Displays only the listed planes</li>
                        <li>hideSidebar - hides sidebar</li>
                        <li>hideButtons - hides all buttons</li>
                        <li>tempTrails=&lt;##&gt; - Shows temporary trails for ## seconds of history</li>
                        <li>largeMode=&lt;1-4&gt; - Cycles Icons size</li>
                        <li>sidebarWidth=### - Size of Sidebar in pixels</li>
                        <li>mil - Military/Interesting</li>
                        <li>kiosk - Hides Ad, hides buttons, and sets planes to Size 2</li>
                        <li>zoom=&lt;1-20&gt; - Sets map zoom level.</li>
                        <li>lat=&lt;XX.XXX&gt;&amp;lon=&lt;XX.XXX&gt; - Centers map at location.</li>
                        <li>airport=&lt;LAX, KJFK, etc.&gt; - Centers map on airport.</li>
                        <li>replay = activates the “replay” toolbar on current view.</li>
                    </ul>
                </p>

                <h4 className='display-5'>Render Customization Parameters</h4>
                <p className='lead'>
                    <ul>
                        <li>mapOrientation=0-360 - normally true north faces up, use this to change which true direction is pointing up.</li>
                        <li>monochromeMarkers=xxxxxx - set constant html color for markers (hexadecimal color).</li>
                        <li>monochromeTracks=xxxxxx - set constant html color for tracks hexadecimal color).</li>
                        <li>outlineWidth=value - set width for the for aircraft icons (webGL disabled)</li>
                        <li>outlineColor=xxxxxx - set outline color (hexadecimal color, webGL disabled)</li>
                        <li>sidebarWidth=xxx - size of sidebar in pixels.</li>
                        <li>scale=0.1-x - overall interface scaling.</li>
                        <li>iconScale=0.1-x - scale the aircraft icons. (multiplies with ?scale)</li>
                        <li>labelScale=0.1-x - scale the aircraft labels. (multiplies with ?scale)</li>
                        <li>mapDim=0.1-1.0 - reduce map brightness (negative values increase brightness)</li>
                        <li>mapContrast=0.1-1.0 - increase the map contrast (negative values decrease brightness)</li>
                    </ul>
                </p>

                <h4 className='display-5'>Troubleshooting</h4>
                <p className='lead'>
                    <ul>
                        <li>reset - reset page settings</li>
                        <li>showerrors - show errors on screen</li>
                    </ul>
                </p>

                <h4 className='display-5'>Heatmaps</h4>
                <p className='lead'>
                    https://globe.adsb.one/?heatmap=200000<br/>
                    <br/>
                    Maximum number of dots to draw is the number after heatmap. Be patient with your browser. Using heatManualRedraw is encouraged to make it easier to move around. Scroll to desired geographic area, then add the heatmap parameters to the URL. On powerful PCs, you can draw as many as 2 million dots. Optional arguments that can be added to the URL:
                    <ul>
                        <li>duration in hours that shall be displayed: &amp;heatDuration=48 (default: 24)</li>
                        <li>set the end of the duration 48 hours into the past: &amp;heatEnd=48 (default:0)</li>
                        <li>radius of the dots: &amp;heatRadius=2</li>
                        <li>opacity of the dots: &amp;heatAlpha=2</li>
                        <li>only redraw the dots when pressing R on the keyboard: &amp;heatManualRedraw</li>
                    </ul>
                </p>
            </Container>
        );
    }
}
