import React from "react";
import { Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class GettingStarted extends React.Component {

    render() {
        return (
            <Container fluid className='h-100'>
                <Helmet>
                    <title>ADS-B One - Getting Started</title>
                    <meta name="description" content="Getting started with ADS-B One"/>
                    <meta property="og:title" content="ADS-B One - Getting Started"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:description" content="Getting started with ADS-B One."/>
                    <meta property="og:url" content="https://adsb.one/getting-started"/>
                </Helmet>
                <Container className='py-3 text-center'>
                    <Row className='py-lg-4'>
                        <Col className='col-lg-6 col-md-8 mx-auto'>
                            <h1 className='fw-light'>Getting Started</h1>
                            <p className='lead text-muted'>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className='row-cols-1 row-cols-sm-2 row-cols-md-2 g-3'>
                        <Col>
                            <Card className='shadow-sm'>
                                <Card.Img src='' />
                                <Card.Body>
                                    <Card.Title>Script</Card.Title>
                                    <Card.Text></Card.Text>
                                    <ButtonGroup>
                                        <Button variant='outline-secondary' href='https://github.com/airplanes-live/feed'>Start</Button>
                                    </ButtonGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='shadow-sm'>
                                <Card.Img src='' />
                                <Card.Body>
                                    <Card.Title>Docker</Card.Title>
                                    <Card.Text></Card.Text>
                                    <ButtonGroup>
                                        <Button variant='outline-secondary' href='https://github.com/sdr-enthusiasts/docker-adsb-ultrafeeder'>Start</Button>
                                    </ButtonGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}
