import React from 'react';
import { Accordion, Container } from 'react-bootstrap';

export default class FAQ extends React.Component {


    render() {
        return (
            <Container fluid className='w-75 h-100'>
                <h1 className='text-center display-3'>FAQ</h1>

                <h4 className='display-5'>1. What is ADS-B One?</h4>
                <p className='lead'>
                    ADS-B One is a one-stop resource for all aero-related information and a community driven aggregator for aero feed data legally dedicated to public interest.
                </p>

                <h4 className='display-5'>2. What is ADS-B?</h4>
                <p className='lead'>
                    Automatic Dependent Surveillance-Broadcast (ADS-B) is a surveillance technology in which an aircraft determines it's position via GPS and periodically broadcasts it and other information about the plane.
                </p>

                <h4 className='display-5'>3. How do I get historical data?</h4>
                <p className='lead'>
                    Historical data can be found at <a href="https://globe.airplanes.one/?r">Globe</a>. For more access to historical data, send inquiries to <a href="mailto:contact@airplanes.live">contact</a>
                </p>

                <h4 className='display-5'>4. Does ADS-B One have an API?</h4>
                <p className='lead'>
                    ADS-B One has a REST API that is a drop-in replacement for the ADSBExchange RapidAPI. For more info see <a href="https://github.com/adsb-one/api">https://github.com/adsb-one/api</a>
                </p>
            </Container>
        );
    }
}
