import * as React from 'react';
import { Container } from 'react-bootstrap';

export default class TermsAndUse extends React.Component {

    render() {
        return(
            <Container fluid className='w-75 h-100'>
                <h1 className='text-center display-3'>Terms Of Use</h1>
                <h5 className='text-center'>Version 1.0</h5>
                <h5 className='text-center'>Last revised on: February 20, 2023</h5>
                <p className='lead'>
                The website located at www.adsb.one (the “<b>Site</b>”) is a copyrighted work belonging to ADS-B One (“<b>Company</b>”, “<b>us</b>”, “<b>our</b>”, and “<b>we</b>”).  
                Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.  
                All such additional terms, guidelines, and rules are incorporated by reference into these Terms. 
                We and you as a user of this Site (“<b>you</b>” and “<b>your</b>”) agree that your access or use of the Site represents good and valid consideration for the rights and obligations provided by these terms of use.
                </p>
                <p className='lead'>
                THESE TERMS OF USE (THESE “<b>TERMS</b>”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR ACCESS AND USE OF THE SITE.  
                BY ACCESSING, INTERACTING WITH, SUBMITTING DATA TO OR USING THE SITE OR ANY OF ITS FEATURES OR FUNCTIONALITY (“<b>ACCESS OR USE</b>,” INCLUDING “ACCESS AND USE” AND “ACCESS AND/OR USE” AND ANY OF THEIR OTHER CORRELATIVE TERMS), YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).  
                YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD.  
                IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
                </p>
                <p className='lead'>
                    <b>
                    PLEASE BE AWARE THAT SECTION 10.2 OF THESE TERMS CONTAINS PROVISIONS GOVERNING HOW DISPUTES THAT YOU AND WE MAY HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY DISPUTES THAT AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE OF YOUR ACCEPTANCE OF THESE TERMS. 
                    IN PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION.  
                    UNLESS YOU TIMELY AND VALIDLY OPT OUT OF THE ARBITRATION AGREEMENT: 
                    (1) YOU WILL ONLY BE PERMITTED TO PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; 
                    AND (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE DISPUTES OR CLAIMS AND/OR TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL.
                    </b>
                </p>
                <h4 className='display-5'>1. ACCOUNTS</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    1.1 <b>Account Creation.</b> In order to use certain features of the Site, you must register for an account (“<b>Account</b>”) and provide certain information about yourself as prompted by the account registration form.  
                    You represent and warrant that: 
                    (a) all required registration information you submit is truthful and accurate; 
                    and (b) you will at all times maintain the accuracy of such information. 
                    You may delete your Account at any time, for any reason, by following the instructions on the Site.  
                    We may suspend or terminate your Account at any time in accordance with Section 8.<br/><br/>
                    1.2 <b>Account Responsibilities.</b> You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account.  
                    You agree to immediately notify us of any unauthorized use, or suspected unauthorized use of your Account, or any other breach of security that may impact us or the Site.  
                    We cannot and will not be liable (and hereby disclaim to the fullest extent permitted under applicable law any such liability) for any loss or damage (including, without limitation, direct, indirect, consequential, punitive or other damages, including loss of benefit, loss of opportunity, loss of profits or diminution of value) arising from your failure to comply with the above requirements or any activities that occur under your Account.
                </p>
                <h4 className='display-5'>2. ACCESS TO THE SITE</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    2.1 <b>License.</b> Subject to these Terms, Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your own personal, noncommercial use. 
                    Your personal use does not include any activities undertaken in the course of academic or educational purposes, not-for-profit commercial activities, data processing or analysis or research purposes.<br/><br/>
                    2.2 <b>Certain Restrictions.</b> The rights granted to you in these Terms are subject to the following restrictions: 
                    (a) you shall not license, sell, rent, lease, transfer, assign, distribute, display, transmit, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on or made available by the Site except to the limited extent expressly granted by the license in Section 2.1 of these Terms; 
                    (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site or utilize in any way the content displayed on or data made available by the Site except to the limited extent expressly granted by the license in paragraph 2.1 of these Terms; 
                    (c) you shall not access the Site or utilize in any way the content displayed or data made available by the in order to build a similar or competitive website, product, or service or for the purposes of research, data analysis or processing or any type of commercial exploitation; and 
                    (d) except as expressly stated herein, no part of the Site or the data made available by the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.  Unless otherwise expressly indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  
                    All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof (regardless of whether or not such copies are made in compliance with these Terms or applicable law).<br/><br/>
                    2.3 <b>Modification.</b> We reserve the right, at any time, to modify, suspend, or discontinue the Site or data made available by the Site (in whole or in part) with or without notice to you.  
                    You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.<br/><br/>
                    2.4 <b>No Support or Maintenance.</b> You acknowledge and agree that Company will have no obligation to provide you with any support or maintenance in connection with the Site or data made available by the Site. 
                    We make no representations or warranties, and expressly disclaim any representations or warranties, as to the accuracy or completeness of the data displayed on the Site or otherwise made available by the Site.<br/><br/>
                    2.5 <b>Ownership.</b> Excluding any User Content that you may provide (defined below), you acknowledge that all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets, in the Site and its content are owned by, or leased or licensed to, Company or Company’s suppliers.  
                    Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1 of these Terms. Company and its suppliers reserve all rights not granted in these Terms.  
                    There are no implied licenses granted under these Terms.<br/><br/>
                    2.6 <b>Feedback.</b> If you provide Company with any feedback or suggestions regarding the Site (“Feedback”), you hereby assign to Company all rights, title and interest in and to such Feedback and agree that Company shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.  
                    Company will treat any Feedback you provide to Company as non-confidential and non-proprietary.  
                    You shall not submit to Company any information or ideas that you consider to be confidential or proprietary.<br/><br/>
                </p>
                <h4 className='display-5'>3. USER CONTENT</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    3.1 <b>User Content.</b> “User Content” means any and all information and content that a user submits to, or uses with, the Site (e.g., content in the user’s profile or postings).  
                    You are solely responsible for your User Content.  
                    You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that personally identifies you or any third party.  
                    You hereby represent and warrant that your User Content does not violate our Acceptable Use Policy (defined in Section 3.3).  
                    You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by Company.  
                    Since you alone are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy.  
                    Company is not obligated to backup any User Content, and your User Content may be deleted at any time without prior notice.  
                    You are solely responsible for creating and maintaining your own backup copies of your User Content if you desire.<br/><br/>
                    3.2 <b>License.</b> You hereby grant (and you represent and warrant that you have the right to grant) to Company an irrevocable, perpetual, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your User Content in the Site.  
                    You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content. 
                    We and you agree that your access or use of the Site represents good and valuable consideration for the license you grant under these Terms.<br/><br/>
                    3.3 <b>Acceptable Use Policy.</b> The following terms constitute our “Acceptable Use Policy”:
                    (a)	You agree not to use the Site to collect, copy, store, reproduce, upload, transmit, re-transmit, provide, display, or distribute any User Content 
                    (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right, 
                    (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, or promotes, encourages, condones or incites racism, bigotry, hatred, or physical harm of any kind against any group, creed, belief system or individual or is otherwise objectionable, 
                    (iii) that is harmful to minors in any way, or 
                    (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.
                    (b)	In addition, you agree not to: 
                    (i) upload, transmit, re-transmit or distribute to or through the Site any computer viruses, worms, or any software intended to damage or alter a computer system or data or any data that you know to be inaccurate, incorrect or misleading; 
                    (ii) send through or using the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; 
                    (iii) use the Site to harvest, collect, store, reproduce, gather or assemble information or data regarding other users or any other individual, including e-mail addresses, without their consent (provided, however, that this limitation shall not apply to or restrict the legitimate access or use of the site to the extent expressly permitted by these Terms); 
                    (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site or through which the site is accessed, or violate the regulations, policies or procedures of such networks, platforms, systems or providers; 
                    (v) attempt to gain unauthorized access to the Site (or to other computer systems, platforms, services, storage, databases or networks connected to or used together with or through the Site), whether through exploits, social engineering, misrepresentation, fraud, password mining or any other means; 
                    (vi) harass or interfere with any other user’s use and enjoyment of the Site; or 
                    (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, downloads, accesses or queries to (or to strip, scrape, transmit, store or mine data from) the Site or data made available by the Site (provided, however, that we conditionally grant to the operators of public, bona fide search engines limited, revocable permission to use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file).<br/><br/>
                    3.4	<b>Enforcement.</b> We reserve the right (but have no obligation) to review, refuse and/or remove any User Content in our sole discretion, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create or engage in any conduct or activities that may give rise to liability for us or any other person. 
                    Such action may include removing or modifying your User Content, terminating your Account in accordance with Section 8, exercising any remedies available under contract, at law or in equity, and/or reporting you to law enforcement authorities or governmental agencies.<br/><br/>
                </p>
                <h4 className='display-5'>4. INDEMNIFICATION</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    You shall indemnify, defend and hold Company (and its affiliates and its and their respective directors, members, shareholders, partners, managers, representatives, officers, employees, agents, successors and assigns) harmless, including costs and attorneys’ fees, from any claim, action, allegation or demand made by any third party due to or arising out of 
                    (a) your access or use of the Site or any data provided by the Site, 
                    (b) your violation of these Terms, 
                    (c) your violation of applicable laws or regulations or 
                    (d) your User Content.  
                    We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate in good faith with our defense of these claims.  
                    You agree not to settle any matter without the prior written consent of Company.  
                    We will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
                </p>
                <h4 className='display-5'>5. THIRD-PARTY LINKS & ADS; OTHER USERS</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    1 <b>Third-Party Links & Ads.</b> The Site may contain links to third-party websites and services, and/or display advertisements for third parties (collectively, “<b>Third-Party Links & Ads</b>”).  
                    Such Third-Party Links & Ads are not under the control of Company, and Company is not responsible (and hereby disclaims any liability) for any Third-Party Links & Ads.  
                    Company provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads or any services, data, information or other actions or activities that they conduct or in which they are engaged.  
                    You use all Third-Party Links & Ads in your sole discretion and at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.  
                    You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links & Ads, and you acknowledge and agree that such investigations are solely your responsibility and not that of the Site.<br/><br/>
                    2 <b>Other Users.</b> Each Site user is solely responsible for any and all of its own User Content.  
                    Since we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.  
                    We make no guarantees regarding the accuracy, reliability, currency, suitability, appropriateness, or quality of any User Content, including any User Content generated or provided by you.  
                    Your interactions with other Site users are solely between you and such users.  You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions.  
                    If there is a dispute between you and any Site user, we are under no obligation to become involved, and your indemnity obligations set forth in Section 4 of these Terms expressly apply to any such dispute.<br/><br/>
                    3 <b>Release.</b> You hereby release and forever discharge the Company (and our affiliates and our and their respective directors, members, shareholders, partners, managers, representatives, officers, employees, and agents, successors and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action, allegation and cause of action of every kind and nature (including personal injuries, death, and property damage, loss of profits, diminution of value or consequential losses or damages), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site or access or use of the Site or the data provided by it (including any interactions with, or act or omission of, other Site users or any Third-Party Links & Ads).  
                    IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”</p>
                <h4 className='display-5'>6. DISCLAIMERS</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND WE (AND OUR AFFILIATES AND SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.  
                    WE (AND OUR AFFILIATES AND SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, COMPREHENSIVE, LEGAL, OR SAFE.  
                    IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE OR THE SHORTEST PERIOD PERMITTED UNDER APPLICABLE LAW.
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU OR MAY BE LIMITED IN ITS APPLICABILITY.  
                    SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU OR MAY BE LIMITED IN ITS APPLICABILITY.
                </p>
                <h4 className='display-5'>7. LIMITATION ON LIABILITY</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR AFFILIATES OR SUPPLIERS AND OUR AND THEIR RESPECTIVE DIRECTORS, MEMBERS, SHAREHOLDERS, PARTNERS, MANAGERS, REPRESENTATIVES, OFFICERS, EMPLOYEES, AND AGENTS, SUCCESSORS AND ASSIGNS) BE LIABLE TO YOU OR ANY THIRD PARTY OR ANY OTHER PERSON FOR ANY LOST PROFITS, LOST DATA, DIMINUTION OF VALUE, LOSS OF USE, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR ACCESS OR USE OF, OR INABILITY TO ACCESS OR USE, THE SITE OR DATA PROVIDED BY THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  
                    ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.<br/><br/>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.  YOU AGREE THAT OUR AFFILIATES OR SUPPLIERS AND OUR AND THEIR RESPECTIVE DIRECTORS, MEMBERS, SHAREHOLDERS, PARTNERS, MANAGERS, REPRESENTATIVES, OFFICERS, EMPLOYEES, AND AGENTS, SUCCESSORS AND ASSIGNS OR ANY OTHER PERSON WITHOUT DIRECT CONTRACTUAL PRIVITY WITH YOU WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.<br/><br/>
                    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU OR MAY BE LIMITED IN ITS APPLICABILITY.
                </p>
                <h4 className='display-5'>8. TERM AND TERMINATION</h4>
                <p className='lead' style={{marginLeft: '20px'}}>
                    Subject to this Section, these Terms will remain in full force and effect while you access or use the Site or the data provided by it, or use or retain any data provided by the Site or any works, compilations, reports, analyses, assessments or other products or services derived therefrom.  
                    We may suspend or terminate your rights to access or use the Site (including your Account) or the data provided by it at any time for any reason (or for no reason, and with or without cause or fault) at our sole discretion, including for any access or use of the Site or data provided by it in violation of these Terms.  
                    Upon termination of your rights under these Terms, your Account and right to access and use the Site and any data provided by it will terminate immediately.  
                    You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases.  
                    Neither we nor any of our affiliates nor any of our or their respective directors, members, shareholders, partners, managers, representatives, officers, employees, and agents, successors and assigns will have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account, termination of the licenses or rights granted herein or deletion of your User Content.  
                    Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2.2 through 2.6, Section 3 and Sections 4 through 10.
                </p>
            </Container>
        );
    }
}