import React from "react";
import { Container } from "react-bootstrap";

export default class OpenSourceSoftware extends React.Component {
    
    render() {
        return(
            <Container fluid className='w-75 h-100'>
            <h1 className='text-center display-3'>Open Source Software</h1>

            <h4 className='display-5'></h4>
            <p className='lead'>
                Almost the entirety of ADS-B One is built on open source software. Below is the core software we use:<br/>
                <br/>
                <a href="https://github.com/haproxy/haproxy/"><em>haproxy</em></a> - HAProxy load balancer<br/>
                <a href="https://github.com/wiedehopf/readsb"><em>readsb</em></a> - ADS-B decoder swiss knife<br/>
                <a href="https://github.com/wiedehopf/tar1090"><em>tar1090</em></a> - Provides an improved webinterface for use with ADS-B decoders readsb / dump1090-fa<br/>
                <a href="https://github.com/wiedehopf/mlat-client"><em>mlat-client</em></a> - Mode S multilateration client<br/>
                <a href="https://github.com/wiedehopf/mlat-server"><em>mlat-server</em></a> - Mode S multilateration server<br/>
            </p>

            </Container>
        );
    }
}