import * as React from 'react';
import { Container } from 'react-bootstrap';

export default class PrivacyPolicy extends React.Component {

    render() {
        return (
            <Container fluid className='w-75 h-100'>
                <h1 className='text-center display-3'>Privacy Policy</h1>
                <h5 className='text-center'>Version 1.0</h5>
                <h5 className='text-center'>Last revised on: 7 Aug 2023</h5>
                <p className='lead'>
                This privacy notice for ADS-B One (<b>"Company"</b>, <b>"we"</b>, <b>"us"</b>, or <b>"our"</b>), 
                describes how and why we might collect, store, use, and/or share (<b>"process"</b>) your 
                information when you use our services (<b>"Services"</b>), such as when you:<br/><br/>
                <ul>
                    <li>Visit our website at <a href='https://www.adsb.one'>https://www.adsb.one</a>, or any website of ours that links to this privacy policy</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                </p>
                
                <h4 className='display-5'>Summary of Key Points</h4>
                <p className='lead'>
                <i>This summary provides key points from our privacy policy.</i><br/>
                <b>What personal information do we process?</b> When you visit, use, or navigate our Services, we 
                may process personal information depending on how you interact with ADS-B One and the Services, 
                the choices you make, and the products and features you use.<br/>
                <b>Do we process any sensitive personal information?</b> We do not process sensitive personal information.<br/>
                <b>Do we receive any information from third parties?</b> We do not receive any information from third parties.<br/>
                <b>How do we process your information?</b> We process your information to provide, improve, and 
                administer our Services, communicate with you, for security and fraud prevention, and to comply
                with law. We may also process your information for other purposes with your consent. We process 
                your information only when we have a valid legal reason to do so.<br/>
                </p>

                <h4 className='display-5'>What Information Do We Collect?</h4>
                <p className='lead'>
                <h4>Personal Information You Disclose To Us</h4>
                <i><b>In Short:</b> We collect personal information that you provide to us.</i><br/><br/>
                We collect personal information that you voluntarily provide to us when you express an interest 
                in obtaining information about us or our products and Services, when you participate in 
                activities on the Services, or otherwise when you contact us.<br/><br/>
                <b>Personal Information Provided By You.</b> The personal information that we collect depends 
                on the context of your interactions with us and the Services, the choices you make, and the 
                products and features you use. The personal information we collect may include the following:
                <ul>
                    <li>IP Address</li>
                    <li>Geolocation Information</li>
                </ul>
                <b>Sensitive Information.</b> We do not process sensitive information.<br/><br/>
                <h4>Information Automatically Collected</h4>
                <i><b>In Short:</b> Some information - such as your IP address and/or browser and device 
                characteristics - is collected automatically when you visit our Services.</i><br/><br/>
                We automatically collect certain information when you visit, use, or navigate the Services. This 
                information does not reveal your specific identity (like your name or contact information) but 
                may include device and usage information, such as your IP address, browser and device 
                characteristics, operating system, language preferences, referring URLs, device name, country, 
                location, information about how and when you use our Services, and other technical information. 
                This information is primarily needed to maintain the security and operation of our Services, and 
                for our internal analytics and reporting purposes.<br/><br/>
                Like many businesses, we also collect information through cookies and similar technologies.<br/><br/>
                The information we collect includes:
                <ul>
                    <li><i>Log and Usage Data.</i>  Log and usage data is service-related, diagnostic, usage, and 
                    performance information our servers automatically collect when you access or use our Services 
                    and which we record in log files. Depending on how you interact with us, this log data may 
                    include your IP address, device information, browser type, and settings and information about 
                    your activity in the Services.</li>
                    <li><i>Device Data.</i>  We collect device data such as information about your computer, 
                    phone, tablet, or other device you use to access the Services. Depending on the device used, 
                    this device data may include information such as your IP address (or proxy server), device 
                    and application identification numbers, location, browser type, hardware model, internet 
                    service provider and/or mobile carrier, operating system, and system configuration 
                    information.</li>
                    <li><i>Location Data.</i>  We collect location data such as information about your device's 
                    location, which can be either precise or imprecise. How much information we collect depends 
                    on the type and settings of the device you use to access the Services. For example, we may 
                    use GPS and other technologies to collect geolocation data that tells us your current 
                    location (based on your IP address). You can opt out of allowing us to collect this 
                    information either by refusing access to the information or by disabling your Location 
                    setting on your device. However, if you choose to opt out, you may not be able to use certain 
                    aspects of the Services.</li>
                </ul>
                </p>

                <h4 className='display-5'>How Do We Process Your Information?</h4>
                <p className='lead'>
                <i><b>In Short:</b> We process your information to provide, improve, and administer our Services, 
                communicate with you, for security and fraud prevention, and to comply with law. We may also 
                process your information for other purposes with your consent.</i><br/><br/>
                <b>We process your personal information for a variety of reasons, depending on how you interact 
                with our Services, including:</b>
                <ul>
                    <li><b>To deliver and facilitate delivery of services to the user.</b> We may process your 
                    information to provide you with the requested service.</li>
                    <li><b>To request feedback.</b> We may process your information when necessary to request 
                    feedback and to contact you about your use of our Services.</li>
                    <li><b>To protect our Services.</b> We may process your information as part of our efforts to 
                    keep our Services safe and secure, including fraud monitoring and prevention.</li>
                    <li><b>To identify usage trends.</b> We may process information about how you use our Services 
                    to better understand how they are being used so we can improve them.</li>
                </ul>
                </p>

                <h4 className='display-5'>When And With Whom Do We Share Your Personal Information?</h4>
                <p className='lead'>
                <i><b>In Short:</b> We may share information in specific situations described in this section 
                and/or with the following third parties.</i><br/><br/>
                We may need to share your personal information in the following situations:
                <ul>
                    <li><b>Affiliates.</b> We may share your information with our affiliates, in which case we 
                    will require those affiliates to honor this privacy policy. Affiliates include our parent 
                    company and any subsidiaries, joint venture partners, or other companies that we control or 
                    that are under common control with us.</li>
                    <li><b>Business Partners.</b> We may share your information with our business partners to 
                    offer you certain products, services, or promotions.</li>
                </ul>
                </p>

                <h4 className='display-5'>Do We Use Cookies And Other Tracking Technologies?</h4>
                <p className='lead'>
                <i><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your 
                information.</i><br/><br/>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or 
                store information.
                </p>

                <h4 className='display-5'>How Long Do We Keep Your Information?</h4>
                <p className='lead'>
                <i><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes 
                outlined in this privacy policy unless otherwise required by law.</i><br/><br/>
                We will only keep your personal information for as long as it is necessary for the purposes set 
                out in this privacy notice, unless a longer retention period is required or permitted by law 
                (such as tax, accounting, or other legal requirements).<br/><br/>
                When we have no ongoing legitimate business need to process your personal information, we will 
                either delete or anonymize such information, or, if this is not possible (for example, because 
                your personal information has been stored in backup archives), then we will securely store your 
                personal information and isolate it from any further processing until deletion is possible.
                </p>

                <h4 className='display-5'>Do We Make Updates To This Policy?</h4>
                <p className='lead'>
                <i><b>In Short:</b> Yes, we will update this policy as necessary to stay compliant with relevant 
                laws.</i><br/><br/>
                We may update this privacy notice from time to time. The updated version will be indicated by an 
                updated date and the updated version will be effective as soon as it is accessible. We encourage 
                you to review this privacy notice frequently to be informed of how we are protecting your 
                information.
                </p>

                <h4 className='display-5'>How Can You Contact Us About This Notice?</h4>
                <p className='lead'>
                If you have questions or comments about this policy, you may email us at <a href='privacy@adsb.one'>privacy@adsb.one</a>.
                </p>
            </Container> 
        )   
    }
}