import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import FeederStatus from './pages/FeederStatus';
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import GettingStarted from './pages/GettingStarted';
import MLATMap from './pages/MLATMap';
import About from './pages/About';
import Partners from './pages/Partners';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQ from './pages/FAQ';
import MapHelp from './pages/MapHelp';
import OpenSourceSoftware from './pages/OpenSourceSoftware';

export default class App extends React.Component {

    render() {
        return (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="getting-started" element={<GettingStarted />} />
                {/* COVERAGE */}
                <Route path="feeder-status" element={<FeederStatus />} />
                <Route path="mlat-map" element={<MLATMap />} />
                {/* DATA */}
                {/* <Route path='api' element={<API />} /> */}
                {/* ABOUT */}
                <Route path='about' element={<About />} />
                <Route path='partners' element={<Partners />} />
                <Route path='data-tou' element={<TermsOfUse />} />
                <Route path='privacy-policy' element={<PrivacyPolicy />} />
                <Route path='oss' element={<OpenSourceSoftware />} />
                <Route path='faq' element={<FAQ />} />
                <Route path='map-help' element={<MapHelp />} />
                {/* <Route path='about-aircraft' element={<AircraftInfo />} /> */}
                <Route path="*" element={<NoPage />} />
            </Routes>
        )
    }
}
