import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class Home extends React.Component {
    keywords = "adsb, ads-b, airplane, plane, aircraft, tracking, gps, tar1090, readsb, mlat, military, civilian, globe, rpi, pi, antenna, 1090, 978, mode-s, mode s, uat, ads-c";


    componentDidMount() {
    }

    render() {
        return (
          <Container fluid className='h-100 text-center' style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url('https://www.thedrive.com/content/2022/01/Planes-At-Gates-Airport.jpg')"}}>
            <Helmet>
              <title>Home</title>
              <meta name="description" content="ADS-B One Homepage"/>
              <meta name="keywords" content={this.keywords}/>
              <meta property="og:title" content="ADS-B One Home"/>
              <meta property="og:type" content="website"/>
              <meta property="og:description" content="A one-stop resource for all aero-related information and a community driven aggregator for aero feed data legally dedicated to public interest."/>
              <meta property="og:url" content="https://adsb.one"/>
              <meta itemProp="name" content="ADS-B One"/>
              <meta itemProp="url" content="https://adsb.one"/>

              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:title" content="ADS-B One"/>
              <meta name="twitter:url" content="https://adsb.one"/>
            </Helmet>

            <Row className='w-75 mx-auto justify-content-md-center overflow-auto'>
              {/* <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center text-white'> */}
              <Col className='text-center text-white'>
                <h1 className='display-1 mb-3'>ADS-B One</h1>
                  <p className='lead mb-5 display-6'>A one-stop resource for all aero-related information and a community driven aggregator
                  for aero feed data legally dedicated to public interest.</p>
                  <div className='d-grid gap-2 d-sm-flex justify-content-sm-center'>
                    <Button variant='light' size='lg' className='px-3 gap-3' href='/about'>Learn More</Button>
                    <Button variant='light' size='lg' className='px-3' href='/getting-started'>Getting Started</Button>
                    <Button variant='light' size='lg' className='px-3' href='/partners'>Partners</Button>
                  </div>
              </Col>
            </Row>
          </Container>
        );
    }
}